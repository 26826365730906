import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import APCategoryBtns from '../../components/preowned/APCategoryBtns'

// markup
const UsedAudemarsPiguetClassicWatches = () => {
  const data = useStaticQuery(
    graphql`
      query queryUsedAudemarsPiguetClassicWatches {
        products: allStrapiProduct(
          filter: { brand: { eq: "Audemars Piguet" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Audemars Piguet Classic Watches'}
      description={
        ' Grays & Sons are experts in buying and selling second hand Audemars Piguet Classic watches with over 40 years of experience in the business'
      }
      canonical={'/fine-watches/audemars-piguet/'}
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/audemars-piguet/">
              <StaticImage
                src="../../images/preowned/used-audemars-piguet-classic-watchesbanner.jpg"
                alt="Pre-Owned Certified Used Audemars Piguet Classic Watches"
                aria-label="Used Audemars Piguet Classic Watches Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div>
            <h1 className="text-3xl">
              About Pre-Owned Used Audemars Piguet Classic Watches
              <br />{' '}
            </h1>

            <p>
              {' '}
              <a href="/w524878-audemars-piguet-royal-oak-35mm-56082b/">
                While Royal Oak watches{' '}
              </a>{' '}
              may be the brand’s most famous models, there are also plenty of fantastic vintage
              Audemars Piguet Classic watches (also known as Classique watches) in the pre-owned
              watch market. Audemars Piguet Classic timepieces don’t belong to a specific named
              collection but rather, these are an assortment of classically designed timepieces,
              often featuring distinctive cases and bracelets crafted from precious metals. One
              advantage of buying pre-owned Audemars Piguet Classic watches is that they can
              generally be purchased at attractive prices since they are not as mainstream as AP’s
              sportier models.
            </p>
            <h2 className="text-3xl">
              Buying and Selling{' '}
              <a href="/search/#search=Audemars+Piguet+Classic">
                {' '}
                Pre-Owned Used Audemars Piguet Classic Watches
              </a>
            </h2>
            <p>
              Grays &amp; Sons are experts in buying and selling second hand Audemars Piguet Classic
              watches with over 40 years of experience in the business. Our team of watch
              specialists can help you buy the pre-owned Audemars Piguet Classic timepiece that’s
              right for you. The Audemars Piguet Classic or Classique collection is an eclectic
              assortment of watches, ranging from round to square to barrel-shaped cases. Since
              these are vintage pieces, secondhand AP Classic watches will have smaller cases than
              modern watches, often measuring between 26mm to 38mm. There are also pre-owned ladies’
              Audemars Piguet Classic cocktail watches with intricate metal work, as well as
              precious gemstones such as diamonds.
            </p>

            <br />
            <p>
              <a href="/">Gray and Sons</a> is the #1 real watch and jewelry store located in
              Surfside, Miami, Florida. Across from the Bal Harbour Shops.
              <a href="/"> Gray and Sons</a> is known for our real watch and jewelry experts
              on-site, open six days a week, and that we're real, not a virtual store. Our customers
              say we are the #1 Miami used watch buyer and seller proudly assisting for the past 42
              years. We're sellers and buyers of pre-owned, used and new{' '}
              <a href="/fine-watches/audemars-piguet/"> Audemars Piguet Classic watches </a>
              in Miami, Miami Beach, Coral Gables, Coconut Grove, South Beach, Bal Harbour,
              Surfside, Sunny Isles , Aventura, Fort Lauderdale and many more areas.
            </p>

            <br />

            <br />

            <h2 className="text justify mx-auto text-2xl">
              Popular Vintage Used Audemars Piguet Classic Models and/or References:
            </h2>

            <li>
              {' '}
              &ensp;
              <a href="/w518462-audemars-piguet-ultra-thin-31mm/">
                Pre-Owned Audemars Piguet Classic Ultra Thin: round case, time-only dial only hour
                and minute hand, ultra-thin manual-winding Caliber 2003
              </a>{' '}
            </li>

            <li>
              {' '}
              &ensp;
              <a href="/w523841-audemars-piguet-classic-32mm/">
                Pre-Owned Audemars Piguet Classic ref. 5072: round case, time-only dial with central
                seconds hand, manual-winding
              </a>{' '}
            </li>

            <li>
              {' '}
              &ensp;
              <a href="/w520468-audemars-piguet-classic-255mm/">
                Pre-Owned Audemars Piguet Classic ref. 5112, round case, time-only dial with seconds
                subdial, automatic
              </a>{' '}
            </li>

            <li>
              {' '}
              &ensp;
              <a href="/w525348-audemars-piguet-classic-31mm/">
                Pre-Owned Audemars Piguet Classic ref. 5093 “Disco Volant,” round case with engine
                turned bezel, two-hands on the dial, manual-winding
              </a>{' '}
            </li>

            <li>
              {' '}
              &ensp;
              <a href="/w523842-audemars-piguet-classic-25mm/">
                Pre-Owned Audemars Piguet Classic Square models: square cases, circa 1960s,
                time-only dials, manual-winding
              </a>{' '}
            </li>

            <li>
              {' '}
              &ensp;
              <a href="/w523550-audemars-piguet-classic-31mm/">
                Pre-Owned Audemars Piguet Classic Tonneau models: barrel-shaped cases, circa
                1960s/1970s,time-only dials
              </a>{' '}
            </li>

            <h2>
              Some AP Classic watches were made before the company used reference numbers. Also,
              depending on the era they were made, you can find secondhand Audemars Piguet Classique
              watches with manual-winding, automatic, and quartz movements. Every secondhand{' '}
              <a href="/search/#search=Audemars+Piguet+Classic"></a>
              AP Classic watch for sale <a /> on Gray &amp; Sons’ website is in stock and ready to
              ship. Furthermore, as an independent Audemars Piguet dealer,{' '}
              <a href="/">Gray &amp; Sons</a> has inventory control and can offer price flexibility.
              Make a deal with one of our decision-makers when you’re ready to purchase a fine
              pre-owned AP Classic timepiece. Alternatively, if you want to know, “Where can I sell
              my used Audemars Piguet Classic watch near me?” Gray &amp; Sons buys luxury timepieces
              too. Visit <a href="https://sellusyourjewelry.com/">sellusyourjewelry.com</a> to start
              the process of selling your used Audemars Piguet watch.
            </h2>
            <h2 className="text-2xl mx-auto">
              Gray &amp; Sons is the Best Source for Buying and Selling Pre-Owned Audemars Piguet
              Classic Watches
            </h2>
            <p>
              Gray and Sons has served thousands of satisfied customers over more than 40 years. Our
              happy clients have named Gray &amp; Sons the number one seller and buyer of used
              Audemars Piguet Classic watches. If you’re in the Miami area, visit the Gray &amp;
              Sons boutique located near the prestigious Bal Harbour Shops for the best selection of
              pre-owned fine timepieces. We are open six days a week where our watch experts can
              help you browse our collection of secondhand AP Classic watches for sale. Gray &amp;
              Sons also has an independent Audemars Piguet service facility for any repair needs.
            </p>
          </div>
        </section>
      </div>
      <div className="w-full flex mx-auto justify-center pb-24 pt-24">
        <a href="/fine-watches/audemars-piguet/">
          <button>Shop Audemars Piguet Classic Watches</button>{' '}
        </a>
      </div>

      <section>
        <h2 className="text-center pb-48 ">IN-STOCK Audemars Piguet Classic Watches:</h2>

        <ProductGrid products={data.products.nodes} />

        <iframe
          className="content-center mx-auto max-width-full"
          src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d7178.937737903439!2d-80.1235056!3d25.8869528!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x88d9b2992b408533%3A0x8d702d6011cda310!2sGray%20and%20Sons%20Jewelers%7C%20Luxury%20Estate%20Watches%20%26%20Jewelry%209595%20Harding%20Ave%20Surfside%2C%20FL%2033154!3m2!1d25.8869528!2d-80.1235056!5e0!3m2!1sen!2sus!4v1655907861510!5m2!1sen!2sus"
          width="1380"
          height="300"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"

        ></iframe>
        <br />
      </section>

      <section>
        <APCategoryBtns />
      </section>

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/watch?v=Ih7LroA6z4A'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default UsedAudemarsPiguetClassicWatches
